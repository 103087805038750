<template>
  <v-app-bar class="white--text py-0 my-0 top-bar" height="56px" app>
    <div class="d-flex">
      <div class="align-self-center">
        <img src="@/assets/UtiliSyncAdminWhite.svg" id="logo" />
      </div>
    </div>
  </v-app-bar>
</template>

<script>
export default {
  name: "LogoTopBar",
};
</script>

<style scoped>
#logo {
  max-height: 45px;
  margin-top: 5px;
}
</style>
